import { UseMutation } from '@Api/api.types'
import { useApplicationInfo } from '@Api/hooks/useApplicationInfo'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '@Api/hooks/usePrepareHeaders'
import { useMutation } from '@tanstack/react-query'
import type { ContentUpdatePayload, ContentUpdateResponse } from './types/contentPayload.types'
import { updateContent } from './updateContent'

export const useUpdateContent = ({
  mutationKeys,
  options
}: UseMutation<ContentUpdateResponse, Error, ContentUpdatePayload>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const pageUpdateKeys = useBuildQueryKeys(mutationKeys)

  return useMutation<ContentUpdateResponse, Error, ContentUpdatePayload>(pageUpdateKeys, {
    mutationFn: (data: ContentUpdatePayload) => {
      return updateContent(data, cmsCoreBackend, zone, headers)
    },
    ...options
  })
}
