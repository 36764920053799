import { UseQuery } from '@Api/api.types'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { queryFn } from '@Api/utils/queryFn'
import { useApplicationInfo } from '@Src/shared/api/hooks/useApplicationInfo'
import { usePrepareHeaders } from '@Src/shared/api/hooks/usePrepareHeaders'
import { useQuery } from '@tanstack/react-query'

export interface Metadata {
  id: number
  name: string
  guid: string
  isEnabled: boolean
  updatedBy: string | null
  createdAt: string
  updatedAt: string | null
}
export interface GetMetadataListResponse {
  data: { authors: Array<Metadata>; userCategories: Array<Metadata>; userTags: Array<Metadata> }
}

export const useMetadataListQuery = ({
  queryKeys,
  options
}: UseQuery<GetMetadataListResponse, Error>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const keys = useBuildQueryKeys([...queryKeys, `metadata-list-zone_${zone.toLowerCase()}`])

  return useQuery<GetMetadataListResponse, Error>(
    keys,
    queryFn(`${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/metadata`, {
      headers
    }),
    options
  )
}
