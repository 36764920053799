import { UseQuery } from '@Api/api.types'
import { useApplicationInfo } from '@Api/hooks/useApplicationInfo'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '@Api/hooks/usePrepareHeaders'
import { queryFn } from '@Api/utils/queryFn'
import type { AudienceObject } from '@dlb-shared-business-components/audience'
import { useQuery } from '@tanstack/react-query'

export interface PodcastModules {
  id: number
  guid: string
  selected_audience: AudienceObject[] | null
  module_data: {
    [key: string]: {
      title: string
      policy_key: string
      video_id: string
      isActive: boolean
      player_id: string
      videoSize: number
      account_id: string
      description: string
      upload_image_path: string
      videoFilename: string
      alternative_text?: string
    }
  }
  authors: string[]
  user_tags: string[]
  user_categories: string[]
  minegocio_groups: string[]
  module_name: string
  schema_name: string
  comment: string | null
  is_shared: boolean
  launch_time: string
  zone: string
  page_sort: number
  created_by_guid: string | null
  created_at: string
  updated_by_guid: string | null
  updated_at: string
  deleted_by_guid: string | null
  deleted_at: string | null
  created_by_user_email: string
  updated_by_user_email: string
  deleted_by_user_email: string | null
  child_modules: []
  delete_recursive: boolean
  is_default_display: boolean | null
  immutable_guid: string
}

export interface GetPodcastResponse {
  data: {
    id: number
    guid: string
    page_data: string | null
    page_name: string
    page_path: string
    zone: string
    launch_time: string
    launch_timezone: string
    is_cascade: boolean | null
    page_template: string
    created_by_guid: string | null
    created_at: string
    updated_by_guid: string | null
    updated_at: string
    deleted_by_guid: string | null
    deleted_at: string | null
    created_by_user_email: string
    updated_by_user_email: string
    deleted_by_user_email: null
    immutable_guid: string
    is_active: boolean
    status: string
    modules: PodcastModules[]
    vendor_ids: []
    campaign_ids: []
    store_ids: string[]
    engagement_ids: []
    authors: string[]
    user_tags: string[]
    user_categories: string[]
    minegocio_groups: string[]
    ddc_ids: number[]
  }
}

export const useGetPodcastQuery = ({
  queryKeys,
  guid,
  options
}: UseQuery<GetPodcastResponse, Error> & { guid: string | undefined }) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const itemsListQueryKeys = useBuildQueryKeys([...queryKeys, guid])

  return useQuery<GetPodcastResponse, Error>(
    itemsListQueryKeys,
    queryFn(`${cmsCoreBackend}/contentedit/page/zone_${zone.toLowerCase()}/${guid}`, {
      headers
    }),
    options
  )
}
