import type { SidebarItem } from 'admin-portal-shared-services'
import { useMemo } from 'react'
import { useEngagePageChangeEvent } from '../useEngagePageChangeEvent/useEngagePageChangeEvent'
import { useMiNegocioPageChangeEvent } from '../useMiNegocioPageChangeEvent/useMiNegocioPageChangeEvent'
import { useSidebarItemsParams } from '../useSidebarItemsParams/useSidebarItemsParams'
import { useGetSidebarItems } from './utils/sidebarItems/useGetSidebarItems'

export function useSidebarItems() {
  const params = useSidebarItemsParams()

  useEngagePageChangeEvent()
  useMiNegocioPageChangeEvent()

  const { sidebarItems } = useGetSidebarItems()

  const items = useMemo<SidebarItem[]>(() => {
    const { list } = sidebarItems
      .reset()
      .shouldShowHome()
      .shouldShowDeepLinkGenerator(params)
      .shouldShowMonitoringDashboard(params)
      .shouldShowCustomer(params)
      .shouldShowPartner(params)
      .shouldShowEngage(params)
      .shouldShowMiNegocio(params)
      .shouldShowAudienceSegmentation(params)
      .shouldShowZoneConfiguration(params)
      .order()

    return list
  }, [params, sidebarItems])

  return { sidebarItems: items }
}
