import { useTranslationsContext } from '@Src/shared/contexts/I18nContext/I18nContext'
import {
  BarChart4,
  CreditCard,
  Home,
  Link as LinkIcon,
  Megaphone,
  Settings,
  Store,
  Users
} from '@hexa-ui/icons'
import React from 'react'
import { WithSidebarIds, WithSidebarPaths } from './WithSidebar.types'

export const useSidebarInfo = () => {
  const {
    translations: { shared: sharedTranslations }
  } = useTranslationsContext()
  const {
    beesCustomerSidebarText,
    beesPartnerStorefrontText,
    beesEngageSidebarText,
    beesMiNegocioText,
    beesDeepLinkGeneratorText,
    beesDeepLinkMonitoringText,
    audienceBuilderSidebarText,
    zoneConfigurationSidebarText
  } = sharedTranslations

  const sidebarInfo = {
    home: {
      id: WithSidebarIds.Home,
      title: 'Home',
      path: WithSidebarPaths.Home,
      icon: () => <Home />
    },
    beesCustomer: {
      id: WithSidebarIds.BeesCustomer,
      title: beesCustomerSidebarText,
      path: WithSidebarPaths.BeesCustomer,
      icon: () => <CreditCard />
    },
    beesPartner: {
      id: WithSidebarIds.BeesPartner,
      title: beesPartnerStorefrontText,
      path: WithSidebarPaths.BeesPartner,
      icon: () => <Store />
    },
    beesEngage: {
      id: WithSidebarIds.BeesEngage,
      title: beesEngageSidebarText,
      path: WithSidebarPaths.Loading,
      icon: () => <Megaphone />
    },
    miNegocio: {
      id: WithSidebarIds.MiNegocio,
      title: beesMiNegocioText,
      path: WithSidebarPaths.MiNegocio,
      icon: () => <Store />
    },
    zoneConfiguration: {
      id: WithSidebarIds.ZoneConfiguration,
      title: zoneConfigurationSidebarText,
      path: WithSidebarPaths.ZoneConfiguration,
      icon: () => <Settings />
    },
    audience: {
      id: WithSidebarIds.Audience,
      title: audienceBuilderSidebarText,
      path: WithSidebarPaths.Audience,
      icon: () => <Users />
    },
    deepLinkGenerator: {
      id: WithSidebarIds.DeepLinkGenerator,
      title: beesDeepLinkGeneratorText,
      path: WithSidebarPaths.DeepLinkGenerator,
      icon: () => <LinkIcon />
    },
    monitoringDashboard: {
      id: WithSidebarIds.MonitoringDashboard,
      title: beesDeepLinkMonitoringText,
      path: WithSidebarPaths.MonitoringDashboard,
      icon: () => <BarChart4 />
    }
  }
  return { sidebarInfo }
}

export const sidebarItemsOrder = {
  [WithSidebarIds.Home]: 0,
  [WithSidebarIds.BeesCustomer]: 1,
  [WithSidebarIds.BeesPartner]: 2,
  [WithSidebarIds.BeesEngage]: 3,
  [WithSidebarIds.MiNegocio]: 4,
  [WithSidebarIds.Audience]: 5,
  [WithSidebarIds.DeepLinkGenerator]: 6,
  [WithSidebarIds.MonitoringDashboard]: 7,
  [WithSidebarIds.ZoneConfiguration]: 8
} as const

export const sidebarCustomEvents = {
  engagePageChange: 'custom:engagePageChange',
  miNegocioPageChange: 'custom:miNegocioPageChange'
}
