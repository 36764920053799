import axios, { AxiosRequestConfig } from 'axios'
import { ContentCreationPayload, ContentCreationResponse } from './types/contentPayload.types'

export async function createContent(
  payload: ContentCreationPayload,
  cmsCoreBackend: string,
  zone: string,
  headers: AxiosRequestConfig['headers']
): Promise<ContentCreationResponse> {
  const { data } = await axios({
    url: `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/content`,
    method: 'POST',
    data: payload,
    headers
  })

  return data
}
