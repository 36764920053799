import zoneConfiguration from '@Configuration/zoneConfiguration'
import { Zones } from '@content-shared-components/image-uploader'
import b2bConfig from '@Src/configuration/b2bStoreConfiguration'
import dtcConfig from '@Src/configuration/dtcStoreConfiguration'
import { zoneCluster, type Cluster } from '@Src/configuration/zoneCluster'
import { Environment } from '@Src/types/environment'
import type { Zone } from 'src/types/zone'
import { ENGAGE_STORE_ID, MI_NEGOCIO_STORE_ID } from '../constants'
import { isInDTC } from '../isInDTC/isInDTC'
import type { GetStores, HasStore } from './zoneUtils.types'

export const zonesWithEngage = (): Zone[] => {
  return (Object.keys(zoneConfiguration) as Zone[]).reduce((result: Zone[], zoneKey: Zone) => {
    const zoneConfig = zoneConfiguration[zoneKey]
    if (zoneConfig?.features?.hasEngage) return [...result, zoneKey]
    return result
  }, [] as Zone[])
}

export const zonesWithMiNegocio = (): Zone[] => {
  return (Object.keys(zoneConfiguration) as Zone[]).reduce((result: Zone[], zoneKey: Zone) => {
    const zoneConfig = zoneConfiguration[zoneKey]
    if (zoneConfig?.features?.hasMiNegocio) return [...result, zoneKey]
    return result
  }, [] as Zone[])
}

export const zonesWithCustomer = (): Zone[] => {
  return (Object.keys(zoneConfiguration) as Zone[]).reduce((result: Zone[], zoneKey: Zone) => {
    const zoneConfig = zoneConfiguration[zoneKey]
    if (zoneConfig?.features?.hasCustomer) return [...result, zoneKey]
    return result
  }, [] as Zone[])
}

export const zonesWithPartnerStorefront = (): Zone[] => {
  return (Object.keys(zoneConfiguration) as Zone[]).reduce((result: Zone[], zoneKey: Zone) => {
    const zoneConfig = zoneConfiguration[zoneKey]
    if (zoneConfig?.features?.hasPartnerStorefront) return [...result, zoneKey]
    return result
  }, [] as Zone[])
}

export const zonesWithAdvancedFields = (): Zone[] => {
  return (Object.keys(zoneConfiguration) as Zone[]).reduce((result: Zone[], zoneKey: Zone) => {
    const zoneConfig = zoneConfiguration[zoneKey]
    if (zoneConfig?.features?.hasAdvancedFields) return [...result, zoneKey]
    return result
  }, [] as Zone[])
}

export const getZoneLabelByCode = (code: string): { code: string; label: string } => {
  const zone = code.toLocaleLowerCase() as Zone

  if ((zone as string) === 'test')
    return {
      code: 'test',
      label: 'Test Zone'
    }

  const countryLabel = zoneConfiguration[zone]?.label || code

  return { code, label: countryLabel }
}

export const getZoneCluster = (zone: Zone, env: Environment): Cluster => {
  const isDTC = isInDTC(window.location.origin)
  if (isDTC) return 'dtc'

  const lowerCaseZone = zone.toLowerCase() as Zone
  if (!zoneCluster[lowerCaseZone]) throw new Error(`Invalid zone: ${zone}`)

  const cluster = zoneCluster[lowerCaseZone][env]
  if (!cluster) throw new Error(`No cluster found for zone: ${zone} and environment: ${env}`)

  return cluster
}

export const isAbiStore = (
  { zone, storeId, env }: { zone: Zones; storeId: string; env: Environment },
  stores = b2bConfig
) => {
  const store = stores[env][zone].stores.find((store) => store.id === storeId)
  if (!store) {
    throw new Error(`Store not found for storeId: ${storeId} and zone: ${zone} on env: ${env}`)
  }
  return store.isAbiStore
}

export const hasStore = (
  { zone, storeId, env, type, cluster }: HasStore,
  b2bStores = b2bConfig,
  dtcStores = dtcConfig
) => {
  const stores = cluster === 'dtc' ? dtcStores : b2bStores
  const store = stores[env][zone].stores.find((store) => {
    const storeType = type === 'customer' ? store.isAbiStore : !store.isAbiStore
    const isValidStore = ![ENGAGE_STORE_ID, MI_NEGOCIO_STORE_ID].includes(store.id)
    return store.id === storeId && storeType && isValidStore
  })
  return !!store
}

export const getStores = (
  { zone, env, type, cluster }: GetStores,
  b2bStores = b2bConfig,
  dtcStores = dtcConfig
) => {
  if (cluster === 'dtc') return dtcStores[env][zone].stores

  const filteredStores = b2bStores[env][zone].stores.filter((store) => {
    const storeType = type === 'customer' ? store.isAbiStore : !store.isAbiStore
    const isValidStore = ![ENGAGE_STORE_ID, MI_NEGOCIO_STORE_ID].includes(store.id)
    return (!type || storeType) && isValidStore
  })
  return filteredStores
}

export const getStoreFeatures = ({
  zone,
  env,
  storeId
}: {
  zone: Zones
  env: Environment
  storeId: string
}) => {
  const stores = isInDTC(window.location.origin) ? dtcConfig : b2bConfig
  const store = stores[env][zone].stores.find((store) => store.id === storeId)
  if (!store) {
    throw new Error(`Store not found for storeId: ${storeId} and zone: ${zone} on env: ${env}`)
  }
  return store.features
}

export const getZoneFeatures = (zone: Zone, zonesConfig = zoneConfiguration) => {
  return zonesConfig[zone]?.features || {}
}
