import { UseMutation } from '@Api/api.types'
import type { AudienceObject } from '@dlb-shared-business-components/audience'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'

interface ImageBanner {
  url: string
  label: string
  altText: string
  image_path: string
}

interface VideoBanner {
  label: string
  altText: string
  callToActionLabel: string
  callToActionLink: string
  video_id: string
  player_id: string
  account_id: string
  policy_key: string
  videoFilename?: string
  videoSize?: number
  videoThumbnail?: string
}

export type ModuleData = ImageBanner | VideoBanner

export interface BannerChildModule {
  updated_by_user_email: string
  guid: string
  schema_name: string
  module_name: string
  vendor_ids: string[]
  campaign_ids: string[]
  ddc_ids: string[]
  engagement_ids: string[]
  is_default_display: boolean
  module_data: Record<string, ModuleData>
  page_sort: number
  immutable_guid: string
  selected_audience: AudienceObject[] | null
  productVendorAssociation?: {
    vendorIds?: string[]
    vendorItemIds?: string[]
  }
}

export interface BannerPayloadModules {
  updated_by_user_email: string
  guid: string
  immutable_guid: string
  schema_name: string
  module_name: string
  vendor_ids: string[]
  ddc_ids: string[]
  module_data: {
    child_modules: Omit<BannerChildModule, 'campaign_ids' | 'module_data'>[]
  }
}

export interface UseAddBannerMutationBody {
  guid: string
  immutable_guid: string
  page_name: string
  page_template: string
  user_email: string
  launch_time: string | Date
  launch_timezone: string | null
  store_id: string
  modules: BannerPayloadModules[]
}

export interface UseAddBannerMutationFn {
  data: UseAddBannerMutationBody
  pagePath: string
}

export interface UseAddBannerMutationResponse {
  data: {
    data: {
      guid: string
    }
  }
}

export const useAddBannerMutation = ({
  mutationKeys,
  options
}: UseMutation<UseAddBannerMutationResponse, Error, UseAddBannerMutationFn>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const addBannerMutationKeys = useBuildQueryKeys(mutationKeys)

  const mutation = useMutation<UseAddBannerMutationResponse, Error, UseAddBannerMutationFn>(
    addBannerMutationKeys,
    {
      mutationFn: ({ pagePath, data }: UseAddBannerMutationFn) => {
        return axios({
          url: `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/page/${pagePath}`,
          method: 'PUT',
          data,
          headers
        })
      },
      ...options
    }
  )

  return mutation
}
