import type { AudienceObject } from '@dlb-shared-business-components/audience'
import { useQuery } from '@tanstack/react-query'
import { UseQuery } from '../api.types'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'
import { queryFn } from '../utils/queryFn'

export interface ImageBanner {
  url: string
  label: string
  altText: string
  image_path: string
}

export interface VideoBanner {
  label: string
  altText: string
  callToActionLabel: string
  callToActionLink: string
  video_id: string
  player_id: string
  account_id: string
  policy_key: string
  videoFilename?: string
  videoSize?: number
  videoThumbnail?: string
}

export type ModuleData = Partial<ImageBanner & VideoBanner>

export type ProductVendorAssociation = {
  vendorIds?: string[]
  vendorItemIds?: string[]
}

export interface ChildModule extends Module {
  vendor_ids: string[]
  ddc_ids: string[]
  campaign_ids: string[]
  engagement_ids: string[]
  is_default_display: boolean
  module_data: Record<string, ModuleData>
  selected_audience: AudienceObject[] | null
  product_vendor_association?: ProductVendorAssociation
}

interface Module {
  comment: null
  created_at: string
  created_by_guid: string
  created_by_user_email: string
  deleted_at: string
  deleted_by_user_email: string
  guid: string
  immutable_guid: string
  id: number
  is_shared: boolean
  launch_time: string
  module_name: string
  page_sort: number
  schema_name: string
  updated_at: string
  updated_by_guid: string
  updated_by_user_email: string
  zone: string
  child_modules: ChildModule[]
}

export type BannerData = {
  campaign_ids: string[]
  created_at: string
  created_by_guid: string
  created_by_user_email: string
  deleted_at: string
  deleted_by_user_email: string
  engagement_ids: string[]
  guid: string
  immutable_guid: string
  id: number
  is_cascade: boolean
  launch_time: string
  launch_timezone: string
  page_data: null
  page_name: string
  page_path: string
  page_template: string
  store_ids: string[]
  updated_at: string
  updated_by_guid: string
  updated_by_user_email: string
  vendors_ids: string[]
  zone: string
  modules: Module[]
  status: string
}

export interface UseBannerDataQuery {
  data: BannerData
}

export const useBannerDataQuery = ({
  queryKeys,
  options,
  guid
}: UseQuery<UseBannerDataQuery, Error> & { guid: string | undefined }) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const itemsListQueryKeys = useBuildQueryKeys([...queryKeys, guid])

  const query = useQuery<UseBannerDataQuery, Error>(
    itemsListQueryKeys,
    queryFn(`${cmsCoreBackend}/contentedit/page/zone_${zone.toLowerCase()}/${guid}`, {
      headers
    }),
    options
  )

  return query
}
