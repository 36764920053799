import { useQuery } from '@tanstack/react-query'
import { UseQuery } from '../api.types'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'
import { queryFn } from '../utils/queryFn'

export type PagePaths = {
  id: string
  root: string
  paths?: unknown[]
  fullPath: string
}
export interface UsePagePathsListQuery {
  data: {
    data: PagePaths[]
  }
}

export const usePagePathsListQuery = ({
  queryKeys,
  options
}: UseQuery<UsePagePathsListQuery, Error>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const itemsListQueryKeys = useBuildQueryKeys(queryKeys)

  return useQuery<UsePagePathsListQuery, Error>(
    itemsListQueryKeys,
    queryFn(`${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/page-paths.json`, {
      headers
    }),
    options
  )
}
