import { useQuery } from '@tanstack/react-query'
import { UseQuery } from '../api.types'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'
import { queryFn } from '../utils/queryFn'

interface DDC {
  ddc_id: string
  label: string
  zone_id: number
}

export type UseDDCsListQuery = {
  data: {
    ddcs: DDC[]
  }
}

export const useDDCsListQuery = ({ queryKeys, options }: UseQuery<UseDDCsListQuery, Error>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const itemsListQueryKeys = useBuildQueryKeys(queryKeys)

  const query = useQuery<UseDDCsListQuery, Error>(
    itemsListQueryKeys,
    queryFn(`${cmsCoreBackend}/contentedit/ddc/zone_${zone.toLowerCase()}`, {
      headers
    }),
    options
  )

  return query
}
