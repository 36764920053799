import { UseMutation } from '@Api/api.types'
import { useApplicationInfo } from '@Api/hooks/useApplicationInfo'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '@Api/hooks/usePrepareHeaders'
import { useMutation } from '@tanstack/react-query'
import { createContent } from './createContent'
import type { ContentCreationPayload, ContentCreationResponse } from './types/contentPayload.types'

export const useCreateContent = ({
  mutationKeys,
  options
}: UseMutation<ContentCreationResponse, Error, ContentCreationPayload>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const pageCreationKeys = useBuildQueryKeys(mutationKeys)

  return useMutation<ContentCreationResponse, Error, ContentCreationPayload>(pageCreationKeys, {
    mutationFn: (data: ContentCreationPayload) => {
      return createContent(data, cmsCoreBackend, zone, headers)
    },
    ...options
  })
}
