import EnvProvider from '@Contexts/EnvContext/EnvContext'
import { FileUploadContextProvider } from '@Contexts/FileUploadContext/FileUploadContext'
import { TranslationsProvider } from '@Contexts/I18nContext/I18nContext'
import ZonesProvider from '@Contexts/ZonesContext/ZonesContext'
import '@I18n/i18nDefinition'
import { NoSsr } from '@mui/material'
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { createGenerateClassName, StylesProvider } from '@mui/styles'
import store from '@Redux/store'
import useCookieService from '@Src/shared/hooks/useCookieService/useCookieService'
import { GlobalStyles } from '@Styles/globalStyles'
import { customTheme } from '@Styles/theme/theme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastContainer, ToastInit } from '@Utils/toast/Toast'
import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css'
import { ThemeProvider } from 'styled-components'
import Router from './routes/routes'
import { FeatureTogglesContextProvider } from './shared/contexts/FeatureTogglesContext/FeatureTogglesContext'
import { ErrorBoundary } from './shared/ErrorBoundary/ErrorBoundary'

export const toast = ToastInit()

export const queryClient = new QueryClient()

const App = (envProps: EnvProps): JSX.Element => {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'admin-portal-cms-mfe',
    seed: 'admin-portal-cms-mfe'
  })

  useCookieService()
  const { optimizelyKey } = envProps
  createFeatureToggleServiceV2('cms-optimizely-toggle', optimizelyKey)

  function closeAllToasts(e: MouseEvent) {
    const target = e.target as HTMLElement

    const toastContainer = document.querySelector('#hexa-toast-container') as HTMLElement

    if (!toastContainer.contains(target)) {
      toast.dismiss()
    }
  }

  useEffect(() => {
    window.addEventListener('click', closeAllToasts)

    return () => window.removeEventListener('click', closeAllToasts)
  }, [])

  return (
    <ErrorBoundary>
      <EnvProvider env={envProps}>
        <Provider store={store}>
          <FeatureTogglesContextProvider>
            <QueryClientProvider client={queryClient}>
              <StyledEngineProvider injectFirst>
                <StylesProvider generateClassName={generateClassName}>
                  <NoSsr>
                    <MuiThemeProvider theme={customTheme}>
                      <ThemeProvider theme={customTheme}>
                        <GlobalStyles />
                        <TranslationsProvider>
                          <ZonesProvider>
                            <FileUploadContextProvider>
                              <ToastContainer />
                              <Router />
                            </FileUploadContextProvider>
                          </ZonesProvider>
                        </TranslationsProvider>
                      </ThemeProvider>
                    </MuiThemeProvider>
                  </NoSsr>
                </StylesProvider>
              </StyledEngineProvider>
            </QueryClientProvider>
          </FeatureTogglesContextProvider>
        </Provider>
      </EnvProvider>
    </ErrorBoundary>
  )
}

export default App
