import { UseMutation } from '@Api/api.types'
import { useApplicationInfo } from '@Api/hooks/useApplicationInfo'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '@Api/hooks/usePrepareHeaders'
import type { AudienceObject } from '@dlb-shared-business-components/audience'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

interface DailyFeedContent {
  relatedInsight: string
  description: string
  endTime: string
  color: string
  image: string
  alternativeText?: string
}

export type ModuleData = DailyFeedContent

export interface DailyFeedPayloadModules {
  updated_by_user_email: string
  guid: string
  module_name: string
  schema_name: string
  launch_time: string
  zone: string
  page_sort: number | null
  is_default_display: boolean
  minegocio_groups: string[]
  selected_audience: AudienceObject[] | null
  module_data: Record<string, DailyFeedContent>
}

export interface UseAddDailyFeedMutationBody {
  guid: string
  page_name: string
  is_active: boolean
  page_template: string
  user_email: string
  launch_time: string | Date
  launch_timezone: string | null
  store_id: string
  modules: DailyFeedPayloadModules[]
}

export interface UseAddDailyFeedMutationFn {
  data: UseAddDailyFeedMutationBody
  pagePath: string
}

export interface UseAddDailyFeedMutationResponse {
  data: {
    message: string
    data: {
      guid: string
      name: string
    }
  }
}

export const useUploadDailyFeedMutation = ({
  mutationKeys,
  options
}: UseMutation<UseAddDailyFeedMutationResponse, Error, UseAddDailyFeedMutationFn>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const uploadDailyFeedKeys = useBuildQueryKeys(mutationKeys)

  return useMutation<UseAddDailyFeedMutationResponse, Error, UseAddDailyFeedMutationFn>(
    uploadDailyFeedKeys,
    {
      mutationFn: ({ pagePath, data }: UseAddDailyFeedMutationFn) => {
        return axios({
          url: `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/page/${pagePath}`,
          method: 'PUT',
          data,
          headers
        })
      },
      ...options
    }
  )
}
