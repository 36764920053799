import globalVars, { ENGAGE_STORE_ID } from '@Src/shared/utils/constants'
import type { SidebarItem } from 'admin-portal-shared-services'
import { WithSidebarIds, WithSidebarPaths } from '../../../../WithSidebar.types'
import {
  sidebarCustomEvents,
  sidebarItemsOrder,
  useSidebarInfo
} from '../../../../WithSidebar.utils'
import type { ItemsParams } from '../../useSidebarItems.types'

export const useGetSidebarItems = () => {
  const { sidebarInfo } = useSidebarInfo()

  const sidebarItems = {
    list: [] as SidebarItem[],

    reset: () => {
      sidebarItems.list = []
      return sidebarItems
    },

    order: () => {
      sidebarItems.list = sidebarItems.list.sort((a, b) => {
        return sidebarItemsOrder[a.id as WithSidebarIds] - sidebarItemsOrder[b.id as WithSidebarIds]
      })
      return sidebarItems
    },

    shouldShowHome: () => {
      sidebarItems.list.push(sidebarInfo.home)
      return sidebarItems
    },

    shouldShowDeepLinkGenerator: (params: ItemsParams) => {
      const { hasDeepLinkGenerator } = params
      if (!hasDeepLinkGenerator) return sidebarItems
      sidebarItems.list.push(sidebarInfo.deepLinkGenerator)
      return sidebarItems
    },

    shouldShowMonitoringDashboard: (params: ItemsParams) => {
      const { hasMonitoringDashboard } = params
      if (!hasMonitoringDashboard) return sidebarItems
      sidebarItems.list.push(sidebarInfo.monitoringDashboard)
      return sidebarItems
    },

    shouldShowCustomer: (params: ItemsParams) => {
      const { hasABIStore } = params
      if (!hasABIStore) return sidebarItems
      sidebarItems.list.push(sidebarInfo.beesCustomer)
      return sidebarItems
    },

    shouldShowPartner: (params: ItemsParams) => {
      const { hasPartnerStore } = params
      if (!hasPartnerStore) return sidebarItems
      sidebarItems.list.push(sidebarInfo.beesPartner)
      return sidebarItems
    },

    shouldShowEngage: (params: ItemsParams) => {
      const { hasEngage, storeId, action } = params
      if (!hasEngage) return sidebarItems
      const getPath = (storeId: string, action: string) => {
        if (storeId === ENGAGE_STORE_ID) {
          return action === globalVars.viewAction
            ? WithSidebarPaths.BeesEngageView
            : WithSidebarPaths.BeesEngageEdit
        }
        return WithSidebarPaths.Loading
      }
      sidebarItems.list.push({
        ...sidebarInfo.beesEngage,
        path: getPath(storeId, action),
        onPageChange: () => dispatchEvent(new Event(sidebarCustomEvents.engagePageChange))
      })
      return sidebarItems
    },

    shouldShowMiNegocio: (params: ItemsParams) => {
      const { hasMiNegocio } = params
      if (!hasMiNegocio) return sidebarItems
      sidebarItems.list.push({
        ...sidebarInfo.miNegocio,
        onPageChange: () => dispatchEvent(new Event(sidebarCustomEvents.miNegocioPageChange))
      })
      return sidebarItems
    },

    shouldShowAudienceSegmentation: (params: ItemsParams) => {
      const { hasAudienceSegmentation, zone, vendorId } = params
      if (!hasAudienceSegmentation) return sidebarItems
      sidebarItems.list.push({
        ...sidebarInfo.audience,
        onPageChange: () => {
          const value = JSON.stringify({
            code: zone.toUpperCase(),
            abiVendorId: vendorId
          })
          localStorage.setItem('vendor', value)
        }
      })
      return sidebarItems
    },

    shouldShowZoneConfiguration: (params: ItemsParams) => {
      const { hasZoneConfiguration } = params
      if (!hasZoneConfiguration) return sidebarItems
      sidebarItems.list.push(sidebarInfo.zoneConfiguration)
      return sidebarItems
    }
  }

  return { sidebarItems }
}
