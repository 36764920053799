import { useQuery } from '@tanstack/react-query'
import { UseQuery } from '../api.types'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'
import { queryFn } from '../utils/queryFn'

export interface UseGetContentTypes {
  data: {
    contentTypes: Array<string>
  }
}

export const useGetContentTypes = ({ queryKeys, options }: UseQuery<UseGetContentTypes, Error>) => {
  const { cmsCoreBackend, zone, storeId } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const itemsListQueryKeys = useBuildQueryKeys(queryKeys)

  return useQuery<UseGetContentTypes, Error>(
    itemsListQueryKeys,
    queryFn(`${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/${storeId}/content-types`, {
      headers
    }),

    options
  )
}
