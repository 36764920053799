import { UseMutation } from '@Api/api.types'
import { useApplicationInfo } from '@Api/hooks/useApplicationInfo'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '@Api/hooks/usePrepareHeaders'
import type { AudienceObject } from '@dlb-shared-business-components/audience'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

interface PodcastContent {
  video_title: string
  title: string
  alternative_text?: string
  description: string
  policy_key: string
  video_id: string
  player_id?: string
  videoSize: number
  account_id: string
  videoFilename: string
  upload_image_path: string
}

export type ModuleData = PodcastContent

export interface PodcastPayloadModules {
  updated_by_user_email: string
  guid: string
  module_name: string
  schema_name: string
  launch_time: string
  zone: string
  page_sort: number | null
  is_default_display: boolean
  authors: string[]
  user_tags: string[]
  user_categories: string[]
  minegocio_groups: string[]
  selected_audience: AudienceObject[] | null
  module_data: Record<string, PodcastContent>
}

export interface UseAddPodcastMutationBody {
  guid: string
  page_name: string
  is_active: boolean
  page_template: string
  user_email: string
  launch_time: string | Date
  launch_timezone: string | null
  store_id: string
  modules: PodcastPayloadModules[]
}

export interface UseAddPodcastMutationFn {
  data: UseAddPodcastMutationBody
  pagePath: string
}

export interface UseAddPodcastMutationResponse {
  data: {
    message: string
    data: {
      guid: string
      name: string
    }
  }
}

export const useUploadPodcastMutation = ({
  mutationKeys,
  options
}: UseMutation<UseAddPodcastMutationResponse, Error, UseAddPodcastMutationFn>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const uploadPodcastKeys = useBuildQueryKeys(mutationKeys)

  return useMutation<UseAddPodcastMutationResponse, Error, UseAddPodcastMutationFn>(
    uploadPodcastKeys,
    {
      mutationFn: ({ pagePath, data }: UseAddPodcastMutationFn) => {
        return axios({
          url: `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/page/${pagePath}`,
          method: 'PUT',
          data,
          headers
        })
      },
      ...options
    }
  )
}
