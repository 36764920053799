import { useMutation } from '@tanstack/react-query'
import { UseMutation } from '../api.types'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'
import { mutationFn } from '../utils/mutationFn'

export interface UseUploadImageMutationResponse {
  data: {
    file: string
    prod: string
    uat: string
    sit: string
    dev: string
    message: string
  }
}

export interface UseUploadImageMutationFn {
  data: FormData
}

export const useUploadImageMutation = ({
  mutationKeys,
  options
}: UseMutation<UseUploadImageMutationResponse, Error, UseUploadImageMutationFn>) => {
  const { imageUpload } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const uploadImageMutationKeys = useBuildQueryKeys(mutationKeys)

  const mutation = useMutation<UseUploadImageMutationResponse, Error, UseUploadImageMutationFn>(
    uploadImageMutationKeys,
    {
      mutationFn: mutationFn<UseUploadImageMutationResponse, FormData>({
        url: imageUpload,
        method: 'POST',
        config: {
          headers
        }
      }),
      ...options
    }
  )

  return mutation
}
