import { UseQuery } from '@Api/api.types'
import { useApplicationInfo } from '@Api/hooks/useApplicationInfo'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '@Api/hooks/usePrepareHeaders'
import { queryFn } from '@Api/utils/queryFn'
import { useQuery } from '@tanstack/react-query'

export interface IMiNegocioSegmentsResponse {
  data: IMiNegocioSegmentsData[]
}

export interface IMiNegocioSegmentsData {
  id: number
  name: string
  zoneId: number
  disabled: boolean
}

export const useGetSegmentsQuery = ({
  queryKeys,
  options
}: UseQuery<IMiNegocioSegmentsResponse, Error>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const itemsListQueryKeys = useBuildQueryKeys([...queryKeys])

  const response = useQuery<IMiNegocioSegmentsResponse, Error>(
    itemsListQueryKeys,
    queryFn(`${cmsCoreBackend}/contentedit/minegocio-group/zone_${zone.toLowerCase()}`, {
      headers
    }),
    options
  )

  return response
}
