import { UseMutation } from '@Api/api.types'
import { useApplicationInfo } from '@Api/hooks/useApplicationInfo'
import { useBuildQueryKeys } from '@Api/hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '@Api/hooks/usePrepareHeaders'
import type { AudienceObject } from '@dlb-shared-business-components/audience'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

interface VideoContent {
  description: string
  upload_image_path: string
  video_id: string
  player_id: string
  account_id: string
  policy_key: string
  video_title: string
  alt_text?: string
  videoFilename?: string
  videoSize?: number
}

export type ModuleData = VideoContent

export interface VideoPayloadModules {
  updated_by_user_email: string
  guid: string
  module_name: string
  schema_name: string
  launch_time: string
  zone: string
  page_sort: number | null
  is_default_display: boolean
  authors: string[]
  user_tags: string[]
  user_categories: string[]
  minegocio_groups: string[]
  module_data: Record<string, VideoContent>
  selected_audience: AudienceObject[] | null
}

export interface UseAddVideoMutationBody {
  guid: string
  page_name: string
  page_template: string
  user_email: string
  launch_time: string | Date
  launch_timezone: string | null
  store_id: string
  is_active: boolean
  modules: VideoPayloadModules[]
}

export interface UseAddVideoMutationFn {
  data: UseAddVideoMutationBody
  pagePath: string
}

export interface UseAddVideoMutationResponse {
  data: {
    message: string
    data: {
      guid: string
      name: string
    }
  }
}

export const useAddVideoMutation = ({
  mutationKeys,
  options
}: UseMutation<UseAddVideoMutationResponse, Error, UseAddVideoMutationFn>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const uploadVideoKeys = useBuildQueryKeys(mutationKeys)

  return useMutation<UseAddVideoMutationResponse, Error, UseAddVideoMutationFn>(uploadVideoKeys, {
    mutationFn: ({ pagePath, data }: UseAddVideoMutationFn) => {
      return axios({
        url: `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/page/${pagePath}`,
        method: 'PUT',
        data,
        headers
      })
    },
    ...options
  })
}
