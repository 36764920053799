import { SkeletonScreen } from '@Components/templates/EditorPage/components/utils/SkeletonScreen'
import { Zones } from '@Hooks/useGetApiHost/useGetApiHost.types'
import { useAppSelector } from '@Redux/hooks/reduxHooks'
import { useLazyGetContentListV2Query } from '@Redux/slices/api/apiSlice'
import { useGetApiHost } from '@Src/shared/hooks/useGetApiHost/useGetApiHost'
import globalVars, { ENGAGE_STORE_ID } from '@Utils/constants'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const LoadingScreen = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const queries = useMemo(
    () => ({
      guid: searchParams.get('guid'),
      action: searchParams.get('action'),
      zoneQuery: searchParams.get('zone'),
      storeQuery: searchParams.get('store_id'),
      redirectedFromQuery: searchParams.get('redirected_from')
    }),
    [searchParams]
  )

  const { guid, action, zoneQuery, storeQuery, redirectedFromQuery } = queries

  const queriesString = `${zoneQuery ? `&zone=${zoneQuery}` : ''}${
    storeQuery ? `&store_id=${storeQuery}` : ''
  }${redirectedFromQuery ? `&redirected_from=${redirectedFromQuery}` : ''}`

  const { rawZone } = useAppSelector(({ application }) => ({
    rawZone: application.rawZone
  }))

  const [getContentList] = useLazyGetContentListV2Query()
  const { cmsCoreBackend: cmsBaseUrl } = useGetApiHost({
    zone: rawZone as Zones
  })

  const findContent = useCallback(async () => {
    const { data: contentList } = await getContentList({
      cmsBaseUrl,
      storeId: ENGAGE_STORE_ID,
      zone: rawZone
    })
    const foundContent = contentList?.find(({ status }: { status: string }) => {
      return status.toLowerCase() === 'live'
    })

    return foundContent
  }, [cmsBaseUrl, getContentList, rawZone])

  useEffect(() => {
    if (!guid) {
      findContent().then((foundContent) => {
        if (foundContent) {
          navigate(
            `/banners-launch-list/view-engage-launch/${foundContent?.guid}?action=${globalVars.viewAction}&zone=${rawZone}&store_id=${ENGAGE_STORE_ID}`
          )
        } else {
          navigate('/home')
        }
      })

      return
    }

    if (action === globalVars.viewAction) {
      navigate(
        `/banners-launch-list/view-engage-launch/${guid}?action=${globalVars.viewAction}${queriesString}`
      )
      return
    }

    navigate(
      `/banners-launch-list/edit-engage-launch/${guid}?action=${globalVars.editAction}${queriesString}`
    )
  }, [action, findContent, guid, navigate, queriesString, rawZone])

  return <SkeletonScreen />
}
