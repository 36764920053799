/* eslint-disable max-lines */
import { api } from '@Api'
import { Zones } from '@content-shared-components/image-uploader'
import { useTranslationsContext } from '@Contexts/I18nContext/I18nContext'
import { useZonesContext } from '@Contexts/ZonesContext/ZonesContext'
import { Browser } from '@hexa-ui/icons'
import { useGetApiHost } from '@Hooks/useGetApiHost/useGetApiHost'
import { useAppDispatch, useAppSelector } from '@Redux/hooks/reduxHooks'
import { useGetContentListV2Query } from '@Redux/slices/api/apiSlice'
import { changeStore } from '@Redux/slices/application/applicationSlice'
import { getContentTypesAndStatusTranslations } from '@Src/modules/shared/utils/getContentTypesAndStatusTranslations'
import { useFeatureTogglesContext } from '@Src/shared/contexts/FeatureTogglesContext/FeatureTogglesContext'
import { useGetEnv } from '@Src/shared/hooks/useGetEnv/useGetEnv'
import useSlicePagination from '@Src/shared/hooks/useSlicePagination/useSlicePagination'
import { isInDTC } from '@Src/shared/utils/isInDTC/isInDTC'
import { getStores } from '@Src/shared/utils/zoneUtils/zoneUtils'
import globalVars from '@Utils/constants'
import getLocalStorage from '@Utils/getLocalStorage/getLocalStorage'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { defaultFiltersValues } from './Constants'
import { DataTableProps, Filters } from './ContentList.types'
import { FiltersTabPanels } from './helpers/FiltersTabPanels'
import useFilter from './hooks/useFilter/useFilter'
import { determiningFilters } from './utils/DeterminingFilters'
import { filterZonesByStoreType } from './utils/FilterZonesByStoreType'
import { formatContentLaunchTableData } from './utils/FormatContentLaunchTableData'
import { GenerateTableColumns } from './utils/GenerateTableColumns'
import { getTableFilterTabs } from './utils/GetTableFilterTabs'
import { handleOnRowClick } from './utils/HandleOnRowClick'
import { handleOnStoreChange } from './utils/HandleOnStoreChange'
import { handleOnZoneChange } from './utils/HandleOnZoneChange'
import { handleSetLocalStorageOnRowPageChange } from './utils/HandleSetLocalStorageOnRowPageChange'
import { invalidateAndUpdateTags } from './utils/InvalidateAndUpdateTags'
import { standardizeContentTypes } from './utils/standardizeContentTypes'
import { iconMapping, TranslatedType } from './utils/TranslatedType'
import { updateSelectedZone } from './utils/UpdateSelectedZone'

const pathsMatch = {
  customer: '/banners-launch-list/customer',
  partner: '/banners-launch-list/partner'
}

const getStoreType = (pathname: string) => {
  if (pathname === pathsMatch.customer) return 'customer'
  if (pathname === pathsMatch.partner) return 'partner'
  return undefined
}

export const useControllerContentList = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const {
    zone: locale,
    storeId,
    rawZone,
    userEmail
  } = useAppSelector(({ application, user }) => ({
    zone: application.zone,
    rawZone: application.rawZone,
    storeId: application.storeId,
    userEmail: user.email
  }))

  const {
    content_type_endpoint_enabled: [
      isContentTypeEndpointEnabled,
      isLoadingContentTypeEndpointToggle
    ]
  } = useFeatureTogglesContext()

  const env = useGetEnv()

  const [selectedZone, setSelectedZone] = useState('Country')
  const [selectedStore, setSelectedStore] = useState('Store')
  const [selectedTab, setSelectedTab] = useState('content-type')
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [targetLaunch, setTargetLaunch] = useState('')
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
  const [advancedFilters, setAdvancedFilters] = useState<Filters>({} as Filters)
  const [contentTypeFilters, setContentTypeFilters] = useState<(string | null)[]>([])
  const [statusFilters, setStatusFilters] = useState<(string | null)[]>(['live'])
  const [filteredContent, setFilteredContent] = useState<DataTableProps[]>([])
  const [finalContent, setFinalContent] = useState<DataTableProps[]>([])
  const [openedDropdown, setOpenedDropdown] = useState(null)
  const userPrefKey = `${userEmail}${globalVars.localStorageUserPreferencesPartialKey}`
  const userPreferences = JSON.parse(getLocalStorage(userPrefKey, JSON.stringify({})))
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(userPreferences?.contentListRowsPerPage) || 10
  )

  const handleOpenDropdown = (guid: any) => {
    setTargetLaunch(guid)
    setOpenedDropdown((prevState) => (prevState === guid ? null : guid))
  }

  const {
    translations: { contentListPage: contentListPageTranslations, shared: sharedTranslations }
  } = useTranslationsContext()

  const tabs = useMemo(() => {
    return getTableFilterTabs(contentListPageTranslations, sharedTranslations)
  }, [contentListPageTranslations, sharedTranslations])

  const contentTypesAndStatusTranslations = useMemo(() => {
    return getContentTypesAndStatusTranslations(sharedTranslations)
  }, [sharedTranslations])

  const filterBoxRef = useRef(null)
  const filterButtonRef = useRef(null)

  const { cmsCoreBackend: cmsBaseUrl } = useGetApiHost({
    zone: rawZone as Zones
  })

  const { data: contentTypesData, refetch: refetchContentTypesData } =
    api.pagePaths.useGetContentTypes({
      queryKeys: ['contentTypes'],
      options: { enabled: !isLoadingContentTypeEndpointToggle && isContentTypeEndpointEnabled }
    })

  const { data: pagePathsData, refetch: refetchPagePaths } = api.pagePaths.usePagePathsListQuery({
    queryKeys: ['contentTypes'],
    options: { enabled: !isLoadingContentTypeEndpointToggle && !isContentTypeEndpointEnabled }
  })

  const mappedContentTypes = contentTypesData?.data?.contentTypes?.map(standardizeContentTypes)
  const mappedPagePaths = pagePathsData?.data?.data?.map(({ root }) =>
    standardizeContentTypes(root)
  )

  const formattedContentTypes = isContentTypeEndpointEnabled ? mappedContentTypes : mappedPagePaths
  const refetchContentTypes = isContentTypeEndpointEnabled
    ? refetchContentTypesData
    : refetchPagePaths

  const {
    data,
    isLoading,
    refetch: refetchContentList
  } = useGetContentListV2Query({ cmsBaseUrl, zone: locale, storeId: String(storeId) })

  const { zonesWithLabel, storesWithLabel, setZonesSelect, cluster, setCluster } = useZonesContext()

  const isCurrentStoreCustomer = pathname === '/banners-launch-list/customer'

  useEffect(() => {
    invalidateAndUpdateTags({ dispatch, rawZone, storeId, cmsBaseUrl })
  }, [cmsBaseUrl, dispatch, rawZone, storeId])

  const {
    handleSubmit,
    control,
    reset: resetFormValues,
    getValues: getFormValues,
    setValue: setFormValue
  } = useForm<Filters>({
    defaultValues: defaultFiltersValues,
    reValidateMode: 'onSubmit'
  })

  const { filterByAdvancedFields, onFilterDelete } = useFilter({
    getFormValues,
    setFormValue,
    setContentTypeFilters,
    setStatusFilters,
    setAdvancedFilters
  })

  const formattedTableData = useMemo(() => {
    return formatContentLaunchTableData({ data, contentTypesAndStatusTranslations })
  }, [contentTypesAndStatusTranslations, data]) as DataTableProps[]

  const { currentPage, goToPage, listLength, itemsPerPage, sliceStartIndex, sliceEndIndex } =
    useSlicePagination(filteredContent, {
      limit: rowsPerPage
    })

  const showFilters = determiningFilters(advancedFilters)

  useEffect(() => {
    updateSelectedZone({ setSelectedZone, setZonesSelect, zonesWithLabel, locale, cluster })
  }, [locale, setZonesSelect, zonesWithLabel, cluster])

  useEffect(() => {
    if (!Object.values(pathsMatch).includes(pathname)) return

    const filteredStores = getStores({
      zone: locale as Zones,
      env,
      type: getStoreType(pathname),
      cluster: isInDTC(window.location.origin) ? 'dtc' : undefined
    })

    const foundStore = filteredStores.find(({ id }) => id === storeId)

    if (foundStore) return setSelectedStore(foundStore.id)

    const localStorageStoreKey = `${globalVars.localStorageStoreKey}:${rawZone}${
      cluster !== 'default' ? `:${cluster}` : ''
    }`

    localStorage.setItem(localStorageStoreKey, filteredStores[0].id)
    setSelectedStore(filteredStores[0].id)
    dispatch(changeStore(filteredStores[0].id))
  }, [pathname])

  const onZoneChange = async (value: string) => {
    handleOnZoneChange({
      value,
      env,
      dispatch,
      setSelectedZone,
      setZonesSelect,
      setSelectedStore,
      setCluster,
      navigate,
      refetchContentList,
      refetchContentTypes
    })
  }

  const onStoreChange = async (value: string) => {
    return handleOnStoreChange({
      value,
      setSelectedStore,
      dispatch,
      locale,
      cluster,
      refetchContentList
    })
  }

  const handleDeleteLaunch = (event: MouseEvent) => {
    event.stopPropagation()
    setIsDeleteModalOpen(true)
  }

  const setLocalStorageOnRowPageChange = () => {
    return handleSetLocalStorageOnRowPageChange({ userPrefKey, rowsPerPage })
  }

  const getTranslatedTypes = TranslatedType()
  const onRowPerPageChangeHub = (pageSize: number) => {
    setRowsPerPage(pageSize)
    setLocalStorageOnRowPageChange()
  }

  const getIcon = (translatedType: keyof typeof getTranslatedTypes, otherProps?: any) => {
    const Icon = React.cloneElement((iconMapping(translatedType) as any) || <Browser />, otherProps)
    return Icon
  }

  useEffect(() => {
    const filteredContent = formattedTableData?.filter(filterByAdvancedFields(advancedFilters))
    setFilteredContent(filteredContent)
  }, [advancedFilters, formattedTableData, filterByAdvancedFields])

  useEffect(() => {
    const finalContent = filteredContent?.slice(sliceStartIndex, sliceEndIndex)
    setFinalContent(finalContent || [])
  }, [filteredContent, sliceEndIndex, sliceStartIndex])

  const onRowClick = (guid: StringOrNumber) => {
    return handleOnRowClick({ data, guid, navigate })
  }

  const columns = GenerateTableColumns({
    getIcon,
    getTranslatedTypes,
    openedDropdown,
    onRowClick,
    handleDeleteLaunch,
    handleOpenDropdown,
    targetLaunch
  })

  useEffect(() => {
    const data = getFormValues()
    setAdvancedFilters(data)
  }, [getFormValues])

  const filtersTabPanels: (React.ReactNode | string)[] = FiltersTabPanels({
    control,
    formattedContentTypes,
    contentTypeFilters,
    setContentTypeFilters,
    statusFilters,
    setStatusFilters,
    setSelectedTab,
    contentTypesAndStatusTranslations
  })

  const onFiltersCancel = useCallback(() => {
    resetFormValues(advancedFilters)
    setContentTypeFilters(advancedFilters?.contentTypeFilter as string[])
    setStatusFilters(advancedFilters?.statusFilter as string[])
    setShowAdvancedFilters(false)
  }, [advancedFilters, resetFormValues])

  const onFiltersSubmit = handleSubmit((data) => {
    setAdvancedFilters(data)
    setShowAdvancedFilters(false)
  })

  const autoCompleteOptions = useMemo(() => {
    return data?.map(({ name, guid }: any) => ({ label: name, value: guid }))
  }, [data])

  const filterUserAllowedZones = () => {
    return filterZonesByStoreType({ isCurrentStoreCustomer, zonesWithLabel })
  }

  return {
    zonesWithLabel,
    storesWithLabel,
    selectedZone,
    selectedStore,
    onZoneChange,
    onStoreChange,
    isCurrentStoreCustomer,
    columns,
    formattedTableData,
    isLoading,
    targetLaunch,
    setIsDeleteModalOpen,
    isDeleteModalOpen,
    onRowClick,
    onRowPerPageChangeHub,
    filtersTabPanels,
    onFilterDelete,
    onFiltersCancel,
    onFiltersSubmit,
    autoCompleteOptions,
    selectedTab,
    setSelectedTab,
    locale,
    showAdvancedFilters,
    advancedFilters,
    setShowAdvancedFilters,
    control,
    getFormValues,
    setAdvancedFilters,
    setFormValue,
    storeId,
    rawZone,
    filterBoxRef,
    filterButtonRef,
    filteredContent,
    filterByAdvancedFields,
    currentPage,
    goToPage,
    listLength,
    itemsPerPage,
    sliceStartIndex,
    sliceEndIndex,
    finalContent,
    showFilters,
    contentListPageTranslations,
    sharedTranslations,
    tabs,
    filterUserAllowedZones
  }
}
