import { api } from '@Src/shared/api/api'
import { useAppDispatch, useAppSelector } from '@Src/shared/redux/hooks/reduxHooks'
import { changeStore } from '@Src/shared/redux/slices/application/applicationSlice'
import globalVars, { ENGAGE_STORE_ID } from '@Src/shared/utils/constants'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { WithSidebarQueryKeys } from '../../WithSidebar.types'
import { sidebarCustomEvents } from '../../WithSidebar.utils'

const buildURL = (base: string, params: Record<string, string | number | undefined>) => {
  const queries = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      queries.append(key, String(value))
    }
  })
  return `${base}?${queries.toString()}`
}

export function useEngagePageChangeEvent() {
  const dispatch = useAppDispatch()
  const { cluster, rawZone: zone } = useAppSelector((state) => state.application)

  const { refetch: getBannersList } = api.banners.useBannersListQuery({
    queryKeys: [WithSidebarQueryKeys.BannersList],
    options: { retry: false, refetchOnWindowFocus: false, enabled: false }
  })

  const navigate = useNavigate()

  useEffect(() => {
    const onEngagePageChange = async () => {
      await dispatch(changeStore(ENGAGE_STORE_ID))

      const clusterKey = cluster !== 'default' ? `:${cluster}` : ''
      const localStorageKey = `${globalVars.localStorageStoreKey}:${zone}${clusterKey}`
      localStorage.setItem(localStorageKey, ENGAGE_STORE_ID)

      const { data } = await getBannersList()

      if (!data) return

      const {
        data: { data: bannersList }
      } = data
      const foundContent = bannersList.find(({ status }) => {
        return status.toLowerCase() === 'live'
      })

      if (!foundContent) return

      navigate(
        buildURL(`/banners-launch-list/view-engage-launch/${foundContent.guid}`, {
          action: globalVars.viewAction,
          zone,
          store_id: ENGAGE_STORE_ID
        })
      )
    }

    window.addEventListener(sidebarCustomEvents.engagePageChange, onEngagePageChange)
    return () => {
      window.removeEventListener(sidebarCustomEvents.engagePageChange, onEngagePageChange)
    }
  }, [cluster, dispatch, getBannersList, navigate, zone])
}
