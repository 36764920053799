import { Locales, Store, Zone } from '@Api/api.types'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'

export interface ZoneFn {
  zone: Zone & { pagePaths: string[] }
  store: Store & { pagePaths: string[] }
  locales: Locales[]
  zoneKey: string
}

export const useAlterZoneMutation = () => {
  const { cmsCoreBackend, zone: zoneKey } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  return useMutation<Record<string, unknown>, Error, ZoneFn>({
    async mutationFn(zone: ZoneFn) {
      return axios({
        method: 'POST',
        url: `${cmsCoreBackend}/contentedit/client/zone/zone_${zoneKey}`,
        data: zone,
        headers
      })
    }
  })
}
