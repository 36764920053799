import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { UseMutation } from '../api.types'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'

export interface ModuleData {
  url: string
}

export interface WebViewPayloadModules {
  updated_by_user_email: string
  guid: string
  immutable_guid: string
  schema_name: string
  module_name: string
  module_data: {
    [key: string]: ModuleData
  }
}

export interface UseAddWebViewMutationBody {
  guid: string
  immutable_guid: string
  page_name: string
  page_template: string
  user_email: string
  launch_time: string | Date
  store_id: string
  modules: WebViewPayloadModules[]
}

export interface UseAddWebViewMutationFn {
  data: UseAddWebViewMutationBody
  pagePath: string
}

export interface UseAddWebViewMutationResponse {
  data: {
    data: {
      guid: string
    }
  }
}

export const useAddWebViewMutation = ({
  mutationKeys,
  options
}: UseMutation<UseAddWebViewMutationResponse, Error, UseAddWebViewMutationFn>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()

  const addWebViewMutationKeys = useBuildQueryKeys(mutationKeys)

  const mutation = useMutation<UseAddWebViewMutationResponse, Error, UseAddWebViewMutationFn>(
    addWebViewMutationKeys,
    {
      mutationFn: ({ pagePath, data }: UseAddWebViewMutationFn) => {
        return axios({
          url: `${cmsCoreBackend}/contentedit/zone_${zone.toLowerCase()}/page/${pagePath}`,
          method: 'PUT',
          data,
          headers
        })
      },
      ...options
    }
  )

  return mutation
}
