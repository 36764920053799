import {
  FileUploadContext,
  useFileUploadContext
} from '@Contexts/FileUploadContext/FileUploadContext'
import { renameFile } from '@Utils/renameFile/renameFile'
import { useMutation } from '@tanstack/react-query'
import axios, { AxiosRequestConfig } from 'axios'
import { UseMutation } from '../api.types'
import { useApplicationInfo } from '../hooks/useApplicationInfo'
import { useBuildQueryKeys } from '../hooks/useBuildQueryKeys'
import { usePrepareHeaders } from '../hooks/usePrepareHeaders'

export interface UseUploadVideoMutationResponse {
  videoId: string | null
  accountId: string | null
}

export interface UseUploadVideoMutationFn {
  videoFile: File
  description?: string
  callToAction?: {
    url: string
    text: string
  }
  thumbnail?: {
    src: string
  }
}

interface UploadBrightcoveVideoFn {
  cmsCoreBackend: string
  zone: string
  headers: AxiosRequestConfig['headers']
  videoFile: File
  description?: string
  callToAction?: UseUploadVideoMutationFn['callToAction']
  thumbnail?: UseUploadVideoMutationFn['thumbnail']
  dispatchFileUploadAction: FileUploadContext['dispatchFileUploadAction']
  fileUploadReducerActions: FileUploadContext['fileUploadReducerActions']
}

const uploadBrightcoveVideo = async ({
  cmsCoreBackend,
  zone,
  headers,
  videoFile,
  description,
  callToAction,
  thumbnail,
  dispatchFileUploadAction,
  fileUploadReducerActions
}: UploadBrightcoveVideoFn) => {
  const { add, update, addError, finished } = fileUploadReducerActions

  const formattedVideoFile = renameFile({
    originalFile: videoFile
  })

  const fileName = description
    ? `${description} - ${formattedVideoFile.name}`
    : formattedVideoFile.name

  let percentageCompleted = 0
  const basePayload = {
    id: fileName,
    fileName
  }

  const dispatchFileUploadError = (percentageCompleted: number) => {
    dispatchFileUploadAction({
      type: addError,
      payload: {
        ...basePayload,
        error: true,
        progress: percentageCompleted
      }
    })
  }

  const dispatchFileUploadUpdate = (
    type: keyof typeof fileUploadReducerActions,
    percentageCompleted: number
  ) => {
    dispatchFileUploadAction({
      type,
      payload: {
        ...basePayload,
        error: false,
        progress: percentageCompleted
      }
    })
  }

  try {
    const createVideoIdBody = {
      name: formattedVideoFile.name,
      description,
      reference_id: crypto.randomUUID(),
      tags: ['video'],
      source_name: `${formattedVideoFile.name} - ${crypto.randomUUID()}`
    }

    if (callToAction) {
      const { text, url } = callToAction
      Object.defineProperty(createVideoIdBody, 'link', {
        value: { text, url },
        enumerable: true
      })
    }

    if (thumbnail) {
      const { src } = thumbnail
      Object.defineProperty(createVideoIdBody, 'images', {
        value: {
          thumbnail: {
            src,
            sources: [{ src }]
          }
        },
        enumerable: true
      })
    }

    const {
      data: { signed_url, api_request_url, video_id, account_id }
    } = await axios({
      url: `${cmsCoreBackend}/brightcove/create-video-id/zone_${zone}`,
      method: 'POST',
      data: createVideoIdBody,
      headers
    })

    axios({
      url: signed_url,
      method: 'PUT',
      headers: { 'Content-Type': '' },
      data: formattedVideoFile,
      onUploadProgress: (progress) => {
        const total = progress?.total ?? 0
        percentageCompleted = Math.round((progress.loaded * 100) / total)

        if (percentageCompleted < 100) {
          dispatchFileUploadUpdate(percentageCompleted === 0 ? add : update, percentageCompleted)
        }
      }
    })
      .then(() => {
        axios({
          url: `${cmsCoreBackend}/brightcove/ingest-video/zone_${zone}`,
          method: 'POST',
          data: { video_id, api_request_url },
          headers
        })
          .then(() => {
            percentageCompleted = 100
            dispatchFileUploadUpdate(update, percentageCompleted)
            dispatchFileUploadAction({
              type: finished,
              payload: {
                ...basePayload,
                error: false,
                progress: percentageCompleted
              }
            })
          })
          .catch(() => {
            dispatchFileUploadError(percentageCompleted)
          })
      })
      .catch(() => {
        dispatchFileUploadError(percentageCompleted)
      })

    return { videoId: video_id as string, accountId: account_id as string }
  } catch (error) {
    dispatchFileUploadError(percentageCompleted)
    return { videoId: null, accountId: null }
  }
}

export const useUploadVideoMutation = ({
  mutationKeys,
  options
}: UseMutation<UseUploadVideoMutationResponse, Error, UseUploadVideoMutationFn>) => {
  const { cmsCoreBackend, zone } = useApplicationInfo()
  const { headers } = usePrepareHeaders()
  const { dispatchFileUploadAction, fileUploadReducerActions } = useFileUploadContext()

  const uploadVideoMutationKeys = useBuildQueryKeys(mutationKeys)

  const mutation = useMutation<UseUploadVideoMutationResponse, Error, UseUploadVideoMutationFn>(
    uploadVideoMutationKeys,
    {
      mutationFn: ({
        videoFile,
        description,
        callToAction,
        thumbnail
      }: UseUploadVideoMutationFn) => {
        return uploadBrightcoveVideo({
          cmsCoreBackend,
          zone,
          videoFile,
          description,
          callToAction,
          thumbnail,
          dispatchFileUploadAction,
          fileUploadReducerActions,
          headers
        })
      },
      ...options
    }
  )

  return mutation
}
